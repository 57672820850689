import { getDownloadURL, ref as refStore, uploadBytes } from "firebase/storage";
import React, { useState } from "react";
import { real_db, store_db } from "../config";
import Resizer from "react-image-file-resizer";
import { ref, set } from "firebase/database";
import { useNavigate } from "react-router-dom";

export default function AddNewGame() {
  const nav = useNavigate();
  const [name, setName] = useState("test");
  const [beerName, setBeerName] = useState("");
  const [beerPercentage, setBeerPercentage] = useState("");
  const [beerImage, setBeerImage] = useState(null);
  const [beerPrice, setBeerPrice] = useState("");
  const [beerId, setBeerId] = useState(0);
  const [questions, setQuestions] = useState([]);

  const handleName = (e) => {
    setName(e.target.value);
  };
  const handleBeerName = (e) => {
    setBeerName(e.target.value);
  };
  const handleBeerPercentage = (e) => {
    setBeerPercentage(e.target.value);
  };

  const handleBeerPrice = (e) => {
    setBeerPrice(e.target.value);
  };
  const handleBeerImage = (e) => {
    const tempImage = e.target.files[0];
    Resizer.imageFileResizer(
      tempImage,
      200,
      400,
      "JPEG",
      100,
      0,
      (uri) => {
        setBeerImage(uri);
      },
      "blob"
    );
  };

  const handleAddNewQuestion = async () => {
    if (beerName.trim()) {
      await uploadBytes(
        refStore(store_db, `${name}/${beerName}`),
        beerImage
      ).then(async (snap) => {
        await getDownloadURL(snap.ref).then((url) => {
          const newQuestion = {
            id: beerId,
            name: beerName,
            percentage: beerPercentage,
            price: beerPrice,
            image: url,
          };

          setQuestions([...questions, newQuestion]);
          setBeerId(beerId + 1);
          setBeerName("");
          setBeerPrice("");
          setBeerPercentage("");
          setBeerImage(null);
        });
      });
    }
  };


  const handleCreateGame = async () => {
    let code = generateGameCode()
    await set(ref(real_db, "games/" + code), {questions: questions, name: name, code: code});
    nav('/admin');
  };

  const generateGameCode = () => {
    const characters =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    let result = "";
    for (let i = 0; i < 4; i++) {
      const randomIndex = Math.floor(Math.random() * characters.length);
      result += characters[randomIndex];
    }
    return result;
  };

  return (
    <div className="w-screen h-screen text-piwo-1 p-4 text-lg">
      <div className="w-full h-full bg-white rounded-lg p-4 flex flex-col justify-between items-center">
        <div className="flex flex-col w-full gap-4">
          <div className="flex w-full gap-2 pb-4 border-b-piwo-1 border-b-2 outline-none">
            <p>NAZWA:</p>
            <input
              type="text"
              onChange={handleName}
              className="border-piwo-1 border-b-2 focus:outline-none"
            />
          </div>
          <h1 className="mt-2 w-full">DODAJ PYTANIA:</h1>
          <div className="w-full">
            <p>NAZWA PIWA:</p>
            <input
              type="text"
              onChange={handleBeerName}
              className="w-full border-piwo-1 border-b-2 focus:outline-none"
              value={beerName}
            />
          </div>
          <div className="w-full">
            <p>CENA PIWA:</p>
            <input
              type="text"
              onChange={handleBeerPrice}
              className="w-full border-piwo-1 border-b-2 focus:outline-none"
              value={beerPrice}
            />
          </div>
          <div className="w-full">
            <p>OBJ. ALK.:</p>
            <input
              type="text"
              onChange={handleBeerPercentage}
              className="w-full border-piwo-1 border-b-2 focus:outline-none"
              value={beerPercentage}
            />
          </div>
          <div className="flex w-full">
            <p className="p-1">ZDJĘCIE:</p>
            <input
              type="file"
              onChange={handleBeerImage}
              className="block w-full text-sm ml-4 text-gray-900 border border-gray-300 rounded-lg cursor-pointer bg-gray-50 p-1"
            />
          </div>
          <div className="w-full flex gap-4">
            <div className="w-1/3">
              {beerImage && (
                <img src={URL.createObjectURL(beerImage)} className="" />
              )}
            </div>
            <div className="w-2/3 max-h-60 overflow-auto">
              <p>Dodane piwa:</p>
              {questions.map((it, key) => {
                return (
                  <div className="w-1/2 flex gap-2" key={key}>
                    <div className="">{it.id}</div>
                    <div className="">{it.name}</div>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
        <div className="w-full">
          <div className="flex flex-col gap-2 justify-center items-center text-white">
            <button
              onClick={handleAddNewQuestion}
              className="w-5/6 bg-green-500 h-8 flex items-center justify-center rounded-lg"
            >
              DODAJ PYTANIE
            </button>
            <button
              onClick={handleCreateGame}
              className="w-5/6 bg-red-500 h-8 flex items-center justify-center rounded-lg"
            >
              CREATE GAME
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
