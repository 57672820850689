import { child, get, onValue, ref, set } from "firebase/database";
import React, { useEffect, useState } from "react";
import { real_db } from "../config";
import firebase from "firebase/compat/app";
import { Link, useParams } from "react-router-dom";
export default function Admin() {
  const [isLoading, setIsLoading] = useState(true);
  const [currMess, setCurrMess] = useState("");
  const [tempMess, setTempMEss] = useState("");

  const [gamesData, setGamesData] = useState(null);

  const handleTempMess = (e) => {
    setTempMEss(e.target.value);
  };
console.log(gamesData);

  useEffect(() => {
    setIsLoading(true);
    get(child(ref(real_db), "/"))
      .then((snap) => {
        if (snap.exists()) {
          setGamesData(snap.val().games);
          setIsLoading(false);
        } else {
          console.log("nie ma");
        }
      })
      .catch((err) => console.error(err));
  }, []);

  // const sendDataToDb = async () => {
  //   await set(ref(real_db, "games/" + generateGameCode()), {
  //     id: Date.now(),
  //     name: "test",
  //   });
  // };

  const generateGameCode = () => {
    const characters =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    let result = "";
    for (let i = 0; i < 4; i++) {
      const randomIndex = Math.floor(Math.random() * characters.length);
      result += characters[randomIndex];
    }
    return result;
  };

  return (
    <div className="flex flex-col justify-center text-2xl items-center">
      <h1 className="text-center mt-8 mb-8">SELECT A GAME:</h1>

      {gamesData &&
        Object.keys(gamesData).map((it) => (
          <Link
            to={`/admin/${it}`}
            key={it}
            className="w-4/5 border-2 border-dashed text-center py-4 mb-4 rounded-2xl border-gray-500"
          >
            <div>{gamesData[it].name.toUpperCase()}</div>
          </Link>
        ))}

      <Link
        to="/admin/new"
        className="w-4/5 border-2 border-dashed text-center py-4 mb-4 rounded-2xl border-gray-500"
      >
        ADD
      </Link>

      {/* <p>tempMEss: {tempMess}</p>
      <p>Mess: {currMess}</p>
      <h1>MAIN</h1>
      <input type="text" onChange={handleTempMess} />
      <button onClick={sendDataToDb}>WYŚLIJ</button> */}
    </div>
  );
}
