/*
        TODO:
        - SPRAWDZENIE CZY KOD DANEJ GRY ISTNIEJE W BAZIE, JAK TAK TO OK, JAK NIE TO ERROR I NIE MA PRZEJŚCIA
        - W USEEFFECT SPRAWDZNIE CZY W BAZIE JEST USER I CODE JAK JEST TO PRZENIESIENIE DO ODPOWIEDNIEJ GRY, JAK NIE MA TO ZOSTAJE
        - DODAĆ GENEROWANIE RANDOMOWEGO OPISU OSOBY + BAZA OPISÓW
        - 
*/

import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { real_db } from "../config";
import {  get, ref, set } from "firebase/database";

export default function Start() {
  const [name, setName] = useState("");
  const [code, setCode] = useState("");
  const [error, setError] = useState(true);
  const nav = useNavigate();

  useEffect(() => {
    const localGameCode = localStorage.getItem("gameCode");
    if (localGameCode !== null) {
      nav("/normal");
    }

    if (name !== "" && code !== "") {
      console.log("jest różne od niczego");
      setError(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [code, name]);
  const handleSetName = (e) => {
    setName(e.target.value);
  };
  const handleSetCode = (e) => {
    setCode(e.target.value);
  };

  const handleStart = async () => {
    let gameMatch = false;
    await get(ref(real_db, `games/${code}`)).then((snap) => {
      if (snap.exists()) {
        gameMatch = true;
      }
    });
    if (gameMatch) {
      let tempUserCode = generateUserCode();
      localStorage.setItem("user", name);
      localStorage.setItem("gameCode", code);
      localStorage.setItem("userCode", tempUserCode);
      set(ref(real_db, `games/${code}/users/` + tempUserCode), {
        name: name,
        userCode: tempUserCode,
      });
      nav("/normal");
    } else {
      setError(true);
    }

    // console.log(reff);
  };

  const generateUserCode = () => {
    const characters =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    let result = "";
    for (let i = 0; i < 5; i++) {
      const randomIndex = Math.floor(Math.random() * characters.length);
      result += characters[randomIndex];
    }
    return result;
  };

  return (
    <div className="h-screen w-screen flex justify-center items-center text-2xl">
      <div className="bg-white text-piwo-1 p-4 flex flex-col gap-2 w-2/3 items-center rounded-lg">
        <p className="font-bold text-center">NAZWA:</p>
        <input
          type="text"
          className=" border-piwo-1 p-2 border-b-2 focus:outline-none text-center w-5/6"
          onChange={handleSetName}
          maxLength="11"
        />
        <p className="font-bold text-center">KOD:</p>
        <input
          type="text"
          className=" border-piwo-1 p-2 border-b-2 focus:outline-none text-center w-5/6"
          onChange={handleSetCode}
          maxLength="4"
        />
        <button
          className={`w-4/5 h-16  rounded-lg text-white mt-10 ${
            error ? "bg-gray-400" : "bg-green-500"
          }`}
          onClick={handleStart}
          disabled={error}
        >
          JOIN
        </button>
      </div>
    </div>
  );
}
