import { child, get, onValue, ref, set } from "firebase/database";
import React, { useEffect, useState } from "react";
import { real_db } from "../config";
import { useParams } from "react-router-dom";
export default function AdminGame() {
  const [gameData, setGameData] = useState(null);
  const [currQuestion, setCurrQuestion] = useState(null);
  const { gameId } = useParams();
  const decCurrQuestion = async () => {
    setCurrQuestion(gameData.previousQuestionId);
    await set(ref(real_db, "curLVL"), gameData.previousQuestionId);
  };
  const incCurrQuestion = async () => {
    setCurrQuestion(gameData.nextQuestionId);
    await set(ref(real_db, "curLVL"), gameData.nextQuestionId);
  };

  useEffect(() => {
    onValue(ref(real_db, `games/${gameId}/answers/${currQuestion}`), (snap) =>
      setGameData(snap.val())
    );
  }, [currQuestion]);
  useEffect(() => {
    get(child(ref(real_db), "/"))
      .then((snap) => {
        if (snap.exists()) {
          setCurrQuestion(snap.val().curLVL);
        }
      })
      .catch((err) => console.error(err));
  }, []);

  console.log(gameData);

  return (
    <div className="w-screen h-screen p-4 text-piwo-1">
      <div className="w-full h-full bg-white rounded-lg flex flex-col justify-between items-center">
        <div className=" w-full p-2 flex flex-col">
          <div className="w-full">
            NUMER PIWA:{" "}
            <span className="text-red-500 font-bold">{currQuestion + 1}</span>
          </div>
          <div className="w-full flex gap-2">
            <div className="w-1/2">
              {gameData && <img src={gameData.image} alt="zdj" />}
            </div>
            {gameData && (
              <div className="w-1/2">
                <p>N:{gameData.name}</p>
                <p>C:{gameData.price}</p>
                <p>%:{gameData.percentage}</p>
              </div>
            )}
          </div>

          <div className="w-full grid grid-cols-2 gap-1 mt-2 max-h-64 overflow-y-auto">
            {gameData &&
              Object.keys(gameData.users).map((it) =>{
                // <div className="" key={it}>{it}</div>
                
                return <div key={it} className={`text-white p-1 text-center rounded-lg ${gameData.users[it] !== ""? 'bg-green-400': 'bg-red-400'}`}>{it}: {gameData.users[it]}</div>
              }
                
              )}
          </div>
        </div>
        <div className="w-full flex gap-4 text-4xl p-4 text-white">
          <button
            className={`w-1/2 cursor-pointer rounded-lg flex justify-center p-2 ${
              currQuestion === 0 ? "bg-gray-500" : "bg-red-500"
            }`}
            onClick={decCurrQuestion}
            disabled={currQuestion === 0 ? true : false}
          >
            BACK
          </button>
          <div
            className="w-1/2 bg-green-500 rounded-lg flex justify-center p-2"
            onClick={incCurrQuestion}
          >
            {gameData && gameData.nextQuestionId === "finish" ? (
              <span>FINISH</span>
            ) : (
              <span>NEXT</span>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
