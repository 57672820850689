import { onValue, ref, set } from "firebase/database";
import React, { useEffect, useState } from "react";
import { real_db } from "../config";
import { useNavigate } from "react-router-dom";
export default function Normal() {
  const nav = useNavigate();
  const [currQuestion, setCurrQuestion] = useState(null);
  const [gameData, setGameData] = useState(null);
  const localUser = localStorage.getItem('user');
  const localCodeData = localStorage.getItem("gameCode");
  console.log(gameData);
  console.log(currQuestion);

  const handleMy = async (e) => {
    await set(ref(real_db, `/games/${localCodeData}/answers/${currQuestion}/users/${localUser}`), e.target.value);
  };

  useEffect(() => {
    onValue(ref(real_db, `/curLVL`), (snap) => {
      setCurrQuestion(snap.val());
      onValue(
        ref(real_db, `games/${localCodeData}/answers/${snap.val()}`),
        (snap2) => setGameData(snap2.val())
      );
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  console.log(localUser);
  gameData && console.log(gameData.users[localUser]);

  const PreGame = () => {
    return <div className="w-full h-full p-2 flex flex-col">
      <div className="w-full h-full bg-white rounded-lg flex justify-center items-center text-2xl text-center p-4">POCZEKAJ NA ROZPOCZĘCIE GRY</div>
    </div>
  }
  const FinGame = () => {
    return <div className="w-full h-full p-2 flex flex-col">
      <div className="w-full h-full bg-white rounded-lg flex justify-center items-center text-2xl text-center p-4">GRA ZAKOŃCZONA <br /> WYNIKI DOSTĘPNE CHUJ WIE KIEDY <br />WIEM ŻE W TYM MOMENCIE JESTEŚ ZGONEM, ALE TO DOBRZE</div>
    </div>
  }
  return (
    <div className="w-screen h-screen p-4 text-black">
      {currQuestion === 'start' ? <PreGame />: currQuestion === 'finish'?  <FinGame />:
      <div className="w-full h-full bg-white rounded-lg flex flex-col justify-between items-center">
          <div className=" w-full p-2 flex flex-col">
          <div className="w-full">
            NUMER PIWA:{" "}
            <span className="text-red-500 font-bold">{currQuestion + 1}</span>
          </div>
          <div className="w-full flex gap-2">
            <div className="w-1/2">
              {gameData && <img src={gameData.image} alt="zdj" />}
            </div>

            <div className="w-1/2 text-sm">
              {gameData &&
                Object.keys(gameData.users).map((it) => {
                  // <div className="" key={it}>{it}</div>

                  return (
                    <p
                      key={it}
                      className={`flex justify-between text-black ${gameData.users[it] !== ""
                        ? "text-black"
                        : "text-red-400"
                        }`}
                    >
                      {" "}
                      <span>{it}</span>
                      {gameData.users[it]}
                    </p>
                  );
                })}
            </div>
          </div>

          {gameData && (
            <div className="w-1/2">
              <p>Nazwa: {gameData.name}</p>
              <p>Cena: {gameData.price}</p>
              <p>OBJ. ALK.: {gameData.percentage}</p>
            </div>
          )}
        </div> 
        <div className="w-full flex flex-col gap-4 text-4xl p-4 text-white">
          {gameData && <div className="text-black text-xl">OCENA: {gameData.users[localUser]} <input type="range" onChange={handleMy} min='1' max="10" className="w-full" value={gameData.users[localUser]} /></div>}
          {/* <div className="w-full bg-green-500 rounded-lg flex justify-center p-2">
            GOTÓW
          </div> */}
        </div>
      </div>}
    </div>
  );
}
