import { initializeApp } from "firebase/app";
import { getDatabase } from "firebase/database";
import { getStorage } from "firebase/storage";

const firebaseConfig = {
  apiKey: "AIzaSyDYxSmxhimLpt4oWfZggQG4pkLYniIZkqA",
  authDomain: "piwoquiz.firebaseapp.com",
  databaseURL: "https://piwoquiz-default-rtdb.europe-west1.firebasedatabase.app",
  projectId: "piwoquiz",
  storageBucket: "piwoquiz.appspot.com",
  messagingSenderId: "360811587969",
  appId: "1:360811587969:web:c204ad5127808685942080"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const real_db = getDatabase(app);
export const store_db = getStorage(app);