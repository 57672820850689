import React from 'react'
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Admin from './pages/Admin';
import Normal from './pages/Normal';
import "./index.css"
import Start from './pages/Start';
import SingleGame from './pages/SingleGame';
import AddNewGame from './pages/AddNewGame';
import AdminGame from './pages/AdminGame';
export default function App() {
  return (
    <div className="App">

      <BrowserRouter>
        <Routes>
          <Route index path='/start' element={<Start/>} />
          <Route path='/admin' element={<Admin/>}/>
          <Route path='/admin/new' element={<AddNewGame/>}/>
          <Route path='/admin/:gameId' element={<SingleGame/>}/>
          <Route path='/admin/:gameId/game' element={<AdminGame/>}/>
          <Route path='/normal' element={<Normal/>}/>
        </Routes>
      </BrowserRouter>
    </div>
  );
}
