import { child, get, onValue, ref, set } from "firebase/database";
import React, { useEffect, useState } from "react";
import { real_db } from "../config";
import { useNavigate, useParams } from "react-router-dom";
export default function SingleGame() {
  const { gameId } = useParams();
  const nav = useNavigate();
  const [gameData, setGameData] = useState(null);
  const [gameUsers, setGameUsers] = useState(null);
  console.log(gameData);
  console.log(gameUsers);

  useEffect(() => {
    onValue(ref(real_db, `games/${gameId}/users`), (snap) => {
      if (snap.exists()) {
        setGameUsers(snap.val());
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    set(ref(real_db, '/curLVL'),'start');
    get(child(ref(real_db), "games/" + gameId))
      .then((snap) => {
        if (snap.exists()) {
          setGameData(snap.val());
        }
      })
      .catch((err) => console.error(err));
      // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);


  const startgame = async () => {
    const usersForQuestion = {};
    Object.values(gameUsers).forEach((user) => {
      usersForQuestion[user.name] = "";
    });
    const updatedQuestions = gameData.questions.map((it, index) => {
      return {
        ...it,
        users: usersForQuestion,
        previousQuestionId: index > 0 ? gameData.questions[index - 1].id : "start", // Poprawne odwołanie do poprzedniego pytania
      nextQuestionId: index < gameData.questions.length - 1 ? gameData.questions[index + 1].id : "finish", // Poprawne odwołanie do następnego pytania
      };
    });
    // console.log(updatedQuestions);
    await set(ref(real_db, 'curLVL'), 0);
    await set(ref(real_db, "currGame"), gameId);
    await set(ref(real_db, `games/${gameId}/answers/`), updatedQuestions);
    nav(`/admin/${gameId}/game`);
  };

  return (
    <div className="w-screen h-screen p-4 text-piwo-1 text-xl">
      <div className="bg-white h-full w-full rounded-xl flex flex-col items-center justify-between p-4">
        <div className="w-full flex flex-col">
          <div className="w-full flex gap-4">
            <p>NAZWA:</p>
            {gameData && <p>{gameData.name.toUpperCase()}</p>}
          </div>

          <div className="w-full flex gap-4">
            <p>KOD GRY:</p>
            {gameData && <p># {gameData.code}</p>}
          </div>
          <br />
          <p>GRACZE:</p>
          <div className="w-full grid grid-cols-2 gap-4">
            {gameUsers &&
              Object.keys(gameUsers).map((it) => {
                return (
                  <div
                    key={it}
                    className="w-full rounded-lg p-3 bg-red-400 text-white text-center"
                  >
                    {gameUsers[it].name.toUpperCase()}
                  </div>
                );
              })}
          </div>
        </div>

        <div className="w-full flex items-center justify-center p-1 text-white">
          <button
            onClick={startgame}
            className="w-5/6 bg-green-500 h-10 flex items-center justify-center rounded-lg"
          >
            START GAME
          </button>
        </div>
      </div>
    </div>
  );
}
